import React from "react";
import HelpPage from "../../components/helpPage";
import { Tabs, Tab, TabPanel, TabList } from "react-web-tabs";
import "react-web-tabs/dist/react-web-tabs.css";
import { graphql, Link } from "gatsby";
import Img from "gatsby-image";

export const query = graphql`
  query {
    zdjecie1: file(relativePath: { eq: "Zadania-w-systemie-CRM.png" }) {
      childImageSharp {
        fluid(maxWidth: 1100, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    zdjecie2: file(relativePath: { eq: "Typy-zadan-w-systemie-CRM.png" }) {
      childImageSharp {
        fluid(maxWidth: 1100, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    zdjecie3: file(
      relativePath: { eq: "Prosty-system-CRM-dodanie-osoby-do-zadania.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1100, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    zdjecie4: file(
      relativePath: { eq: "Tworzenie-zadania-w-prostym-systemie-CRM.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1100, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    zdjecie5: file(
      relativePath: { eq: "Prosty-system-CRM-wyswietlanie-zadan.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1100, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    zdjecie6: file(
      relativePath: { eq: "Tworzenie-zdarzen-w-prostym-systemie-CRM.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1100, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    zdjecie7: file(
      relativePath: { eq: "Typy-zdarzen-w-prostym-systemie-CRM.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1100, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    zdjecie8: file(
      relativePath: {
        eq: "Prosty-system-CRM-wykonanie-zadania-i-stworzenie-zdarzenia.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1100, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    zdjecie9: file(relativePath: { eq: "System-CRM-zdarzenie.png" }) {
      childImageSharp {
        fluid(maxWidth: 1100, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    zdjecie10: file(
      relativePath: { eq: "System-CRM-wyswietlanie-zdarzen-1.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1100, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    zdjecie11: file(
      relativePath: { eq: "Tworzenie-typow-zadan-i-zdarzen-w-systemie-CRM.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1100, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    zdjecie12: file(
      relativePath: {
        eq: "Tworzenie-typow-zadan-i-zdarzen-w-prostym-systemie-CRM.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1100, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    zdjecie13: file(
      relativePath: { eq: "Tworzenie-typow-zadan-i-zdarzen-W-CRM-1.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1100, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

const ZadaniaIZdarzenia = ({ data }) => {
  return (
    <HelpPage
      name="Tworzenie zadań i zdarzeń"
      metaTitle="Pomoc - zadania i zdarzenia w systemie CRM - QuickCRM"
      metaDescription="Zadania i zdarzenia w systemie CRM - pomoc • Tworzenie i wyświetlanie zadań wybranych pracowników, wyszukiwanie zdarzeń, podgląd • Łatwa koordynacja i planowanie pracy zespołu • Wypróbuj QuickCRM i sprzedawaj więcej, pierwsze 30 dni bezpłatnie!"
    >
      <div>
        <p>
          <strong>Zadanie</strong> – jest to zaplanowana w&nbsp;systemie
          aktywność. Może to być np. zaplanowane spotkanie lub przygotowanie
          oferty.
        </p>
        <p>
          <strong>Zdarzenie –</strong>&nbsp;to wykonane zadanie. Jeśli swoje
          zaplanowane zadanie oznaczysz jako wykonane zostanie ono automatycznie
          zapisane jako zadanie ukończone oraz zostanie utworzone zdarzenie.
          Dodatkowo, jeśli to klient skontaktuje się z&nbsp;Tobą, możesz
          utworzyć zdarzenie, które będzie widoczne na Twojej liście oraz
          przypisane zostanie do kontrahenta.
        </p>
      </div>
      <Tabs defaultTab="zakladka1">
        <TabList>
          <Tab tabFor="zakladka1">[1] Dodawanie zadań</Tab>
          <Tab tabFor="zakladka2">[2] Wyświetlanie zadań</Tab>
          <Tab tabFor="zakladka3">[3] Dodawanie zdarzeń</Tab>
          <Tab tabFor="zakladka4">[4] Wyświetlanie zdarzeń</Tab>
          <Tab tabFor="zakladka5">[5] Typy zadań</Tab>
        </TabList>
        <TabPanel tabId="zakladka1">
          <p>
            Zadania można tworzyć w wielu miejscach w systemie. Z poziomu
            kalendarza wystarczy dwa razy kliknąć w konkretny dzień lub wybrać
            opcję “dodaj nowe zadanie”, aby rozpocząć dodawanie zadania.
          </p>
          <p>
            Z zadaniem możesz powiązać kontrahenta, dokumenty oraz szanse
            sprzedaży, a także tworzyć powiadomienia.
          </p>
          <br />
          <p>
            <strong>Tworzenie zadania</strong>
          </p>
          <Img
            style={{
              width: 1100,
              margin: "0 auto",
              maxWidth: "100%"
            }}
            alt="Tworzenie zadania w prostym systemie CRM"
            fluid={data.zdjecie1.childImageSharp.fluid}
          />
          <p>
            [1] Utwórz zadanie, klikając na przycisk “dodaj nowe zadanie” lub
            dwa razy klikając w wybrany dzień na kalendarzu.
          </p>
          <Img
            style={{
              width: 1100,
              margin: "0 auto",
              maxWidth: "100%"
            }}
            alt="Tworzenie zadania w prostym systemie CRM"
            fluid={data.zdjecie2.childImageSharp.fluid}
          />
          <p>
            [2]&nbsp;Wybierz typ zadania. W&nbsp;systemie domyślnie zdefiniowane
            są cztery typy zadań:
          </p>
          <ul>
            <li>Zaplanowane spotkanie z&nbsp;klientem</li>
            <li>Skontaktować się</li>
            <li>Przygotować ofertę</li>
            <li>Dostarczyć ofertę</li>
          </ul>
          <Img
            style={{
              width: 1100,
              margin: "0 auto",
              maxWidth: "100%"
            }}
            alt="Tworzenie zadania w prostym systemie CRM"
            fluid={data.zdjecie3.childImageSharp.fluid}
          />
          <p>
            [3] Wybierz osobę, do której chcesz przypisać zadanie. Możesz
            tworzyć zadania dla siebie, możesz też delegować zadania innym
            pracownikom. Wystarczy, że wpiszesz pierwsze litery, a system
            podpowie Ci osoby.
          </p>
          <Img
            style={{
              width: 1100,
              margin: "0 auto",
              maxWidth: "100%"
            }}
            alt="Tworzenie zadania w prostym systemie CRM"
            fluid={data.zdjecie4.childImageSharp.fluid}
          />
          <p>
            [4]&nbsp;Możesz wybrać osobę klienta, z&nbsp;którą związane jest
            zadanie. Wystarczy, że wpiszesz pierwsze litery, a&nbsp;system
            podpowie Ci kontrahentów.
          </p>
          <p>
            [5] Jeśli chcesz, ustaw przypomnienie, które wyświetli się wraz
            z&nbsp;opisem w&nbsp;odpowiednim czasie.
          </p>
          <p>
            [6] Powiąż zadanie z&nbsp;kontrahentem, aby tworzyć wspólną historię
            wszystkich kontaktów, do której masz dostęp w&nbsp;karcie
            kontrahenta.&nbsp;Wystarczy, że wpiszesz pierwsze litery,
            a&nbsp;system podpowie Ci kontrahentów.
          </p>
          <p>
            [7]&nbsp;Jeśli jest to zadanie, które wykonujesz systematycznie,
            zaznacz, że jest to zadanie cykliczne i&nbsp;wybierz częstotliwość
            powtarzania.
          </p>
        </TabPanel>
        <TabPanel tabId="zakladka2">
          <p>
            W kalendarzu możesz wyświetlić swoje zadania oraz zadania wybranych
            osób.
          </p>
          <p>
            Domyślnie w kalendarzu wyświetlają się zadania aktywne. Każdy
            pracownik może mieć przypisany inny kolor do swoich zadań. Takie
            rozwiązanie zwiększa przejrzystość kalendarza i ułatwia koordynację
            pracy. Aby wybrać kolor, należy przejść do ustawień profilu.
          </p>
          <Img
            style={{
              width: 1100,
              margin: "0 auto",
              maxWidth: "100%"
            }}
            alt="Tworzenie zadania w prostym systemie CRM"
            fluid={data.zdjecie5.childImageSharp.fluid}
          />
          <p>[1] Wybierz osoby, których zadania chcesz wyświetlić.</p>
          <p>[2] Wyświetlone zadania kierownika.</p>
          <p>[3] Wyświetlone zadania pracownika.</p>
        </TabPanel>
        <TabPanel tabId="zakladka3">
          <p>
            Zdarzenia mają takie same atrybuty jak zadania (oprócz przypomnień
            czy ustawień cyklicznych).
          </p>
          <Img
            style={{
              width: 1100,
              margin: "0 auto",
              maxWidth: "100%"
            }}
            alt="Tworzenie zadania w prostym systemie CRM"
            fluid={data.zdjecie6.childImageSharp.fluid}
          />
          <p>[1] Dodaj nowe zdarzenie.</p>
          <Img
            style={{
              width: 1100,
              margin: "0 auto",
              maxWidth: "100%"
            }}
            alt="Tworzenie zadania w prostym systemie CRM"
            fluid={data.zdjecie7.childImageSharp.fluid}
          />
          <p>
            [2]&nbsp;Wybierz typ zdarzenia. W&nbsp;systemie domyślnie
            zdefiniowanych jest pięć typów zdarzeń:
          </p>
          <ul>
            <li>Skontaktowano się</li>
            <li>Zrealizowano spotkanie z&nbsp;klientem</li>
            <li>Przygotowano ofertę</li>
            <li>Dostarczono ofertę handlową</li>
            <li>Kontakt przychodzący</li>
          </ul>
          <p>
            Zdarzenie może powstać automatycznie, jeśli wykonasz zaplanowane
            zadanie.
          </p>
          <Img
            style={{
              width: 1100,
              margin: "0 auto",
              maxWidth: "100%"
            }}
            alt="Tworzenie zadania w prostym systemie CRM"
            fluid={data.zdjecie8.childImageSharp.fluid}
          />
          <p>
            [3] Wybierz zaplanowane zadanie w kalendarzu — dwa razy kliknij, aby
            przejść do szczegółów zadania. Uzupełnij dane i kliknij “wykonaj”,
            aby przejść do widoku zdarzenia.
          </p>
          <Img
            style={{
              width: 1100,
              margin: "0 auto",
              maxWidth: "100%"
            }}
            alt="Tworzenie zadania w prostym systemie CRM"
            fluid={data.zdjecie9.childImageSharp.fluid}
          />
          <p>
            [4] Zdarzenie, które właśnie utworzyłeś, zostało powiązane z
            zaplanowanym wcześniej zadaniem. W tym miejscu możesz zobaczyć jego
            szczegóły. Teraz wystarczy, że uzupełnisz dane i klikniesz “Zapisz”,
            aby powstało zdarzenie.
          </p>
        </TabPanel>
        <TabPanel tabId="zakladka4">
          <p>
            W kalendarzu możesz wyświetlić swoje zdarzenia&nbsp;oraz zdarzenia
            wybranych osób.
          </p>
          <p>
            Każdy pracownik może mieć przypisany inny kolor do swoich zdarzeń.
            Rozwiązanie to zwiększa przejrzystość kalendarza oraz ułatwia
            koordynację pracy. Aby wybrać kolor, należy przejść do{" "}
            <Link to="/pomoc/ustawienia-profilu/">ustawień profilu</Link>.
          </p>
          <Img
            style={{
              width: 1100,
              margin: "0 auto",
              maxWidth: "100%"
            }}
            alt="Tworzenie zadania w prostym systemie CRM"
            fluid={data.zdjecie10.childImageSharp.fluid}
          />
          <p>[1] Wybierz osoby, których zdarzenia chcesz wyświetlić.</p>
          <p>[2] Wyświetlone&nbsp;zdarzenia pracownika.</p>
          <p>[3]&nbsp;Wyświetlone&nbsp;zdarzenia kierownika.</p>
        </TabPanel>
        <TabPanel tabId="zakladka5">
          <p>
            <strong>Tworzenie własnych typów zadań i zdarzeń</strong>
          </p>
          <Img
            style={{
              width: 1100,
              margin: "0 auto",
              maxWidth: "100%"
            }}
            alt="Tworzenie zadania w prostym systemie CRM"
            fluid={data.zdjecie11.childImageSharp.fluid}
          />
          <p>
            [1] Wybierz z&nbsp;menu “Ustawienia”, a&nbsp;następnie “Zadania
            i&nbsp;zdarzenia”.
          </p>
          <p>[2] Przejdź do typów zadań.</p>
          <Img
            style={{
              width: 1100,
              margin: "0 auto",
              maxWidth: "100%"
            }}
            alt="Tworzenie zadania w prostym systemie CRM"
            fluid={data.zdjecie12.childImageSharp.fluid}
          />
          <p>
            [3] Znajdujesz się na liście wszystkich zadań i zdarzeń w systemie.
            Dodaj nowy typ.
          </p>
          <Img
            style={{
              width: 1100,
              margin: "0 auto",
              maxWidth: "100%"
            }}
            alt="Tworzenie zadania w prostym systemie CRM"
            fluid={data.zdjecie13.childImageSharp.fluid}
          />
          <p>[4] Dodaj nazwę typu.</p>
          <p>
            [5] Określ priorytet, który decyduje o&nbsp;kolejności wyświetlania
            typów zdań na liście, np. podczas tworzenia zadania.
          </p>
          <p>
            [6] Zaznacz, jeśli tworzysz zdarzenie. W&nbsp;innym przypadku
            powstanie zadanie.
          </p>
          <p>[7] Pamiętaj o&nbsp;nadaniu uprawnień odpowiednim profilom.</p>
          <p>[8] Zapisz ustawienia.</p>
          <p>
            Tworząc zadanie możesz sprawdzić, czy Twoje ustawienia były
            poprawne.
          </p>
        </TabPanel>
      </Tabs>
    </HelpPage>
  );
};

export default ZadaniaIZdarzenia;
